import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const DmdModular = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      seo={{
        title: "DMD Modular",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #D5613D, #E26A45, #F37E5A, #E26A45, #D5613D )",
        icons: "#EB6941",
        navClass: "dmd",
        ogImage: require("../../../assets/img/portfolio/dmd_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/dmd-modular/",
      }}
    >
      <PortfolioHeader name="dmd" height="105" />
      <section className="container-fluid dmd_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>DMD Modular</h1>
              <ul>
                <li>Website</li>
                <li>Lead Generation Campaign</li>
                <li>Banner Ads</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                DMDmodular features advanced off-site building methods offering
                turn-key products in a fast–track modular and construction
                solution, which offers minimal disruption to the surrounding
                facilities operation. We guaranty high-end quality finishing
                product as well as up to 50% time saving due to production in a
                dry, factory controlled environment, and then transportation of
                the complete building parts to a fully prepared site, for a
                faster return on your investment.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid dmd_section_3">
        <div className="row row_1">
          <div className="col-md-6 offset-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/dmd_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-end no-gutters row_2">
          <div className="col-md-7">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/dmd_main_1.png")}
              alt=""
            />
          </div>
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/dmd_main_2.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/dmd_main_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/dmd_main_4.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default DmdModular;
